<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Hureedesign"
      title="Hureedesign"
    >
    <!-- <img src="img/brand/itlab.png" style="width: 120px; height: auto;" alt=""> -->
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Киоскууд',
            icon: 'ni ni-tv-2 text-primary',
            path: '/kiosks'
          }"
        />
        <sidebar-item :link="{name: 'Зураг', icon: 'ni ni-album-2 text-orange', path: '/photos'}"/>
        <sidebar-item :link="{name: 'Бичлэг', icon: 'ni ni-album-2 text-success', path: '/videos'}"/>
        <sidebar-item :link="{name: 'Текст', icon: 'ni ni-single-copy-04 text-primary', path: '/texts'}"/>
        <sidebar-item :link="{name: 'Хэрэглэгчид', icon: 'ni ni-bullet-list-67 text-info', path: '/users'}"/>
        <div align="center">
          <a href="https://api.kiosk.gazar.mn/contents/app.apk">
            <el-button type="danger" icon="el-icon-download" style="margin-top: 50px; width: 70%; background: #353848; border: 1px solid #353848; border-radius: 7px;" size="small">АПП ТАТАХ</el-button></a>
        </div>
        <!-- <sidebar-item>
          <a href="http://example.itlab.mn/contents/app.apk">
            <el-button type="danger" icon="el-icon-download" style="width: 100%; background: #353848; border: 1px solid #353848; border-radius: 7px;" size="small">АПП ТАТАХ</el-button></a>
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue'
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
