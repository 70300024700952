import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import KioskAuthLayout from '@/layout/KioskAuthLayout'
import CustomLayout from '@/layout/CustomLayout'

function AuthCheck(to, from, next) {
  var isAuthenticated = false;
  var token = localStorage.getItem("token");
  if (token) {
    isAuthenticated = true;
  }
  if (isAuthenticated) {
    next();
  } else {
    next("/login");
  }
}

function LoginCheck(to, from, next) {
  var isAuthenticated = true;
  var token = localStorage.getItem("token");
  if (!token) {
    isAuthenticated = false;
  }
  if (!isAuthenticated) {
    next();
  } else {
    next("/kiosks");
  }
}

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'kiosks',
      beforeEnter: AuthCheck,
      component: DashboardLayout,
      children: [
        {
          path: '/kiosk-detail/:id',
          name: 'Киоск дэлгэрэнгүй',
          component: () => import('./views/KioskDetail.vue')
        },
        {
          path: '/kiosks',
          name: 'Киоскууд',
          component: () => import('./views/Kiosk.vue')
        },
        {
          path: '/add-kiosk',
          name: 'Шинэ киоск бүртгэх',
          component: () => import('./views/NewKiosk.vue')
        },
        {
          path: '/photos',
          name: 'Зурагнууд',
          component: () => import('./views/Photos.vue')
        },
        {
          path: '/add-user',
          name: 'Хэрэглэгч бүртгэх',
          component: () => import('./views/NewUser.vue')
        },
        {
          path: '/videos',
          name: 'Бичлэгнүүд',
          component: () => import('./views/Videos.vue')
        },
        {
          path: '/users',
          name: 'Хэрэглэгчид',
          component: () => import('./views/Users.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/texts',
          name: 'texts',
          component: () => import(/* webpackChunkName: "demo" */ './views/texts.vue')
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      beforeEnter: LoginCheck,
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'slide',
      component: CustomLayout,
      children: [
        {
          path: '/slide',
          name: 'slide',
          component: () => import(/* webpackChunkName: "demo" */ './views/Slide.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'AuthKiosk',
      component: KioskAuthLayout,
      children: [
        {
          path: '/auth-kiosk',
          name: 'auth-kiosk',
          component: () => import(/* webpackChunkName: "demo" */ './views/AuthKiosk.vue')
        }
      ]
    },
  ]
})
